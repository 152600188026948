export type NumInterval = [number | null, number | null];

export type ExtendedNumInterval =
  | NumInterval
  | {
      start: number | null;
      end: number | null;
    };

function parseInterval(i: NumInterval): [number, number] {
  return [i[0] === null ? -Infinity : i[0], i[1] === null ? Infinity : i[1]];
}

function formatInterval([start, end]: [number, number]): NumInterval {
  const resStart = isFinite(start) ? start : null;
  const resEnd = isFinite(end) ? end : null;
  return [resStart, resEnd];
}

const extractInterval = (v: ExtendedNumInterval): NumInterval =>
  Array.isArray(v) ? v : [v.start, v.end];

const intersects = (
  v1: ExtendedNumInterval,
  v2: ExtendedNumInterval
): boolean => {
  const [i1, i2] = [extractInterval(v1), extractInterval(v2)];
  const [start1, end1] = parseInterval(i1);
  const [start2, end2] = parseInterval(i2);

  return start1 <= end2 && end1 >= start2;
};

const intersection = (
  v1: ExtendedNumInterval,
  v2: ExtendedNumInterval
): NumInterval | null => {
  const [i1, i2] = [extractInterval(v1), extractInterval(v2)];
  if (!intersects(i1, i2)) {
    return null;
  }

  const [start1, end1] = parseInterval(i1);
  const [start2, end2] = parseInterval(i2);

  const start = Math.max(start1, start2);
  const end = Math.min(end1, end2);
  const res = formatInterval([start, end]);
  return res;
};

const contains = (
  v1: ExtendedNumInterval,
  v2: ExtendedNumInterval
): boolean => {
  const [i1, i2] = [extractInterval(v1), extractInterval(v2)];
  const [start1, end1] = parseInterval(i1);
  const [start2, end2] = parseInterval(i2);

  return start1 <= start2 && end1 >= end2;
};

const containsPoint = (target: ExtendedNumInterval, val: number): boolean => {
  const i1 = extractInterval(target);
  const [start, end] = parseInterval(i1);
  return start <= val && end >= val;
};

const union = (
  v1: ExtendedNumInterval,
  v2: ExtendedNumInterval
): NumInterval | null => {
  const [i1, i2] = [extractInterval(v1), extractInterval(v2)];
  if (!intersects(i1, i2)) {
    return null;
  }

  const [start1, end1] = parseInterval(i1);
  const [start2, end2] = parseInterval(i2);

  const start = Math.min(start1, start2);
  const end = Math.max(end1, end2);
  const res = formatInterval([start, end]);
  return res;
};

const mask = (
  target: ExtendedNumInterval,
  masks: ExtendedNumInterval[]
): NumInterval[] => {
  const i = extractInterval(target);
  const res: NumInterval[] = [];
  masks.forEach((m) => {
    const tmp = intersection(i, m);
    if (tmp) {
      res.push(tmp);
    }
  });
  return res;
};

export {
  intersects,
  intersection,
  parseInterval,
  formatInterval,
  contains,
  containsPoint,
  union,
  mask,
};
