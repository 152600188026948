import { ILogger, Log, UserManager, UserManagerSettings } from "oidc-client-ts";

const backupPathKey = "prevPath";

export const backupUrlPath = (logger?: ILogger) => {
  const value = window.location.pathname + window.location.search;
  logger?.debug("[OidcClient] backupUrlPath set value", value);
  localStorage.setItem(backupPathKey, value);
};

export const getUrlPathBackup = (logger?: ILogger) => {
  const value = localStorage.getItem(backupPathKey);
  logger?.debug("[OidcClient] getUrlPathBackup", value);
  return value;
};

export const removeUrlPathBackup = (logger?: ILogger) => {
  logger?.debug("[OidcClient] removeUrlPathBackup");
  return localStorage.removeItem(backupPathKey);
};

export type TUserManagerProps = {
  clientId: string;
  authority: string;
  overrideConfig?: Partial<UserManagerSettings>;
  onAddSilentRenewError?: () => void;
  customLogger?: ILogger;
  logLevel?: "none" | "error" | "warn" | "info" | "debug";
};

export function createUserManager(props: TUserManagerProps) {
  const {
    clientId,
    authority,
    overrideConfig = {},
    onAddSilentRenewError,
    customLogger,
    logLevel = "error",
  } = props;

  const config: UserManagerSettings = {
    redirect_uri: window.location.origin + "/auth/callback",
    response_type: "code",
    scope: "openid",
    post_logout_redirect_uri: window.location.origin,
    automaticSilentRenew: true,
    revokeTokensOnSignout: true,
    client_id: clientId,
    authority,
    ...overrideConfig,
  };

  const userManager = new UserManager(config);

  // Hack: can be fixed by adding end_session_endpoint on backend
  const endSessionEndpoint = authority.replace("/connect", "/logout");
  userManager.metadataService.getEndSessionEndpoint = () =>
    Promise.resolve(endSessionEndpoint);

  userManager.events.addSilentRenewError(() => {
    customLogger?.info(
      "[OidcClient] Outdated refresh token. Redirect to sign in",
    );
    backupUrlPath(customLogger);
    onAddSilentRenewError?.();

    userManager.signinRedirect();
  });

  Log.setLogger(customLogger || console);
  switch (logLevel) {
    case "none":
      Log.setLevel(Log.NONE);
      break;
    case "error":
      Log.setLevel(Log.ERROR);
      break;
    case "warn":
      Log.setLevel(Log.WARN);
      break;
    case "info":
      Log.setLevel(Log.INFO);
      break;
    case "debug":
      Log.setLevel(Log.DEBUG);
      break;
  }

  return userManager;
}

Log.setLogger(console);
