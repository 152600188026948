/* eslint-disable formatjs/no-literal-string-in-jsx */
import React from "react";
import s from "./style.module.scss";
import { Logo } from "@frontend/airfoil";

export const COPYRIGHT = `© ${new Date().getFullYear()} Assaia International AG`;

const DashboardOff: React.FC = () => {
  return (
    <div className={s.page}>
      <div className={s.content}>
        <div className={s.msg}>
          <h1>Assaia turnaround control is switched off</h1>
          <div className={s.offSymbol} />
          <div className={s.contact}>
            For questions please write to
            <br />
            <a href={"mailto:support@assaia.com"}>ch@assaia.com</a>
          </div>
        </div>
      </div>
      <footer>
        <Logo />
        <span>{COPYRIGHT}</span>
      </footer>
    </div>
  );
};

export default DashboardOff;
