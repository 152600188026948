import { useIntlOutsideProvider } from "@hooks/useIntlOutsideProvider";
import React, { ComponentProps, HTMLProps, ReactNode } from "react";
import { cn } from "@services/react";
import { Button, Icons24, Spinner } from "@frontend/airfoil";
import { reportMessage } from "@services/logger";

import s from "./style.module.scss";

type ConditionalProps<T> =
  | ({
      withRefreshButton?: true;
      refreshButtonProps?: ComponentProps<typeof Button> & {
        withoutRedirect?: boolean;
        redirectUrl?: string;
      };
    } & T)
  | ({
      withRefreshButton?: false;
      refreshButtonProps?: never;
    } & T);

export type ErrorMessageProps = ConditionalProps<
  { message?: ReactNode } & HTMLProps<HTMLDivElement>
>;

export const ErrorMessage = ({
  message,
  className,
  children,
  withRefreshButton,
  refreshButtonProps: {
    withoutRedirect,
    redirectUrl,
    ...refreshButtonProps
  } = {},
  ...restProps
}: ErrorMessageProps) => {
  const intl = useIntlOutsideProvider();

  const defaultErrorMessage = intl?.formatMessage(
    {
      defaultMessage: "Assaia ApronAI is unavailable. {br} Please try later...",
      description: "Error Message default title",
    },
    { br: <br /> }
  );

  const errorMessage = message || defaultErrorMessage;

  if (!errorMessage) {
    return <Spinner />;
  }

  const refreshButton = withRefreshButton ? (
    <Button
      icon={<Icons24 name="LoopLeftLine" />}
      {...refreshButtonProps}
      className={cn(s.refreshBtn, refreshButtonProps.className)}
      onClick={(event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
        reportMessage("Reload page from error state");
        // @ts-ignore
        refreshButtonProps.onClick?.(event);

        if (!withoutRedirect) {
          window.history.pushState({}, document.title, redirectUrl || "/");
        }
        window.location.reload();
      }}
    >
      {intl?.formatMessage({
        defaultMessage: "Refresh",
        description: "Error Message default refresh button label",
      })}
    </Button>
  ) : null;

  return (
    <div className={cn(s.errorMessage, className)} {...restProps}>
      <Icons24 name="Alert" className={s.towerIcon} />
      <h1 className={s.title}>{errorMessage}</h1>
      {refreshButton}
      {children}
    </div>
  );
};
