import { generateMockData } from "@services/mocks/mockUtils";
import {
  TUrlWithGet,
  TApiGetResponse,
  TApiConfigReturnType,
  API_V2_PREFIX,
} from ".";

// /flights/aircrafts
const aircraftsUrl: TUrlWithGet = "/flights/aircraft_groups";

export type TAircraftsResponse = TApiGetResponse<typeof aircraftsUrl>;

export const getAircraftsApiConfig =
  (): TApiConfigReturnType<TAircraftsResponse> => {
    return {
      config: {
        url: API_V2_PREFIX + aircraftsUrl,
      },
      getMockResponse: generateMockData<TAircraftsResponse>([]),
    };
  };
