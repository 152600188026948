import { cloneDeep, isFunction } from "lodash";
import { di } from "@di";

export const createLogger = (
  label: string,
  checkLoggerEnabled?: () => boolean
) => {
  const checkerToUse = isFunction(checkLoggerEnabled)
    ? checkLoggerEnabled
    : () => di.resolve("configOverrides").isDebug;

  return {
    log: (...args: Parameters<typeof console.log>) => {
      const newArgs = cloneDeep([`[${label}]`, ...args]);
      if (checkerToUse()) {
        console.log(...newArgs);
      }
    },
    error: (...args: Parameters<typeof console.error>) => {
      const newArgs = [`[${label}]`, ...args];
      if (checkerToUse()) {
        console.error(...newArgs);
      }
    },
  };
};
