import { generateMockData } from "@services/mocks/mockUtils";
import qs from "qs";
import {
  TApiUrlWithoutPrefix,
  TApiGetResponse,
  TApiGetQueryParams,
  TApiConfigReturnType,
  API_V2_PREFIX,
} from ".";

const nextFlightsUrl: TApiUrlWithoutPrefix = "/stands/next_flights";
type TNextFlightsParams = TApiGetQueryParams<typeof nextFlightsUrl>;

export type TNextFlightsResponse = TApiGetResponse<typeof nextFlightsUrl>;

export const getNextFlightsApiConfig = (
  data: {
    standIds: string[];
    limit?: number;
  },
  signal?: AbortSignal
): TApiConfigReturnType<TNextFlightsResponse> => {
  const params: TNextFlightsParams = {
    limit: data.limit,
    stand_ids: data.standIds,
  };

  return {
    config: {
      url: API_V2_PREFIX + nextFlightsUrl,
      method: "GET",
      signal,
      params,
      paramsSerializer: (v) => {
        return qs.stringify(v, { arrayFormat: "repeat" });
      },
    },
    getMockResponse: generateMockData<TNextFlightsResponse>([]),
  };
};
