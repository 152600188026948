// /turnarounds/summary /turnarounds

import { TurnaroundParameterKey } from "@frontend/configuration";
import { generateMockData } from "@services/mocks/mockUtils";
import { cloneDeep } from "lodash";
import {
  TApiUrlWithoutPrefix,
  TApiPostResponse,
  TApiPostParams,
  TApiConfigReturnType,
  API_V2_PREFIX,
} from ".";

const turnaroundsSummaryUrl: TApiUrlWithoutPrefix = "/turnarounds/summary";

const turnaroundsUrl: TApiUrlWithoutPrefix = "/turnarounds/";

export type TTurnaroundsResponse = TApiPostResponse<typeof turnaroundsUrl>;

export type TTurnaroundsSummaryResponse = TApiPostResponse<
  typeof turnaroundsSummaryUrl
>;

type _TurnParams = TApiPostParams<typeof turnaroundsUrl>;
type TRawTurnParams = Omit<_TurnParams, "sort">;
export type TTurnaroundsParams = TRawTurnParams & {
  sort?: {
    param: TurnaroundParameterKey;
    mode: "asc" | "desc";
  };
};

type _SummaryParams = TApiPostParams<typeof turnaroundsSummaryUrl>;
type TRawTurnParams_Summary = Omit<_SummaryParams, "sort">;

export type TTurnaroundsSummaryParams = Partial<TRawTurnParams_Summary> & {
  sort?: {
    param: TurnaroundParameterKey;
    mode: "asc" | "desc";
  };
};

const FE_PARAM_TO_FLIGHT_PARAM_MAP: Record<
  TurnaroundParameterKey,
  TOriginalSort["param"] | null
> = {
  aibt: "aircraft_on_stand_end_ts",
  pobt: "pobt",
  aobt: "aircraft_on_stand_start_ts",
  initial_eobt: "initial_eobt",
  asrt: "asrt",
  apbg: "apbg",
  sibt: "sibt",
  eibt: "eibt",
  flight_ardt: "flight_ardt",
  sobt: "sobt",
  eobt: "eobt",
  ltd: "ltd",
  tobt: "tobt",
  aldt: "aldt",
  tsat: "tsat",
  asat: "asat",
  aircraft_start_ts: "aircraft_start_ts",
  aircraft_end_ts: "aircraft_end_ts",
  aircraft_or_pushback_ts: "aircraft_or_pushback_ts",
  aircraft_ready_ts: "aircraft_ready_ts",
  predicted_aircraft_ready_ts: "predicted_aircraft_ready_ts",
  ctot: "ctot",
  cobt: null,
  eldt: null,
};

type TOriginalSort = NonNullable<_TurnParams["sort"]>;

// TODO: get rid of overloads
// Do not use api params directly to the rest of the app
export function getTurnaroundsApiConfig(
  params: TTurnaroundsParams,
  withSummary: boolean,
  signal?: AbortSignal
): TApiConfigReturnType<TTurnaroundsResponse, TTurnaroundsParams>;
export function getTurnaroundsApiConfig(
  params: TTurnaroundsSummaryParams,
  withSummary: boolean,
  signal?: AbortSignal
): TApiConfigReturnType<TTurnaroundsSummaryResponse, TTurnaroundsSummaryParams>;
export function getTurnaroundsApiConfig(
  { sort, ...params }: TTurnaroundsParams | TTurnaroundsSummaryParams,
  withSummary: boolean = false,
  signal?: AbortSignal
): TApiConfigReturnType<
  TTurnaroundsResponse,
  TTurnaroundsParams | TTurnaroundsSummaryParams
> {
  const _params = cloneDeep(params);

  const url = withSummary
    ? API_V2_PREFIX + "/turnarounds/summary"
    : API_V2_PREFIX + "/turnarounds/";

  if ("turn_start_filter" in _params) {
    if (_params.turn_start_filter?.from) {
      _params.turn_start_filter.from /= 1000;
    }

    if (_params.turn_start_filter?.to) {
      _params.turn_start_filter.to /= 1000;
    }
  }

  if ("param_filters" in _params) {
    _params.param_filters = _params.param_filters?.map((filter) => {
      if (filter.from) {
        filter.from /= 1000;
      }

      if (filter.to) {
        filter.to /= 1000;
      }

      return filter;
    });
  }

  let convertedSort: TOriginalSort | undefined = undefined;
  if (sort) {
    const convertedParam = FE_PARAM_TO_FLIGHT_PARAM_MAP[sort.param];
    if (convertedParam) {
      convertedSort = {
        mode: sort.mode,
        param: convertedParam,
      };
    }
  }

  return {
    config: {
      url,
      method: "POST",
      data: {
        ..._params,
        // FIXME: get rid of overloads
        // @ts-ignore
        sort: convertedSort,
      },
      signal,
    },
    getMockResponse: generateMockData<TTurnaroundsResponse>([]),
  };
}
