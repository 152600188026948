import { generateMockData } from "@services/mocks/mockUtils";
import { paths } from "../apiSpec";
import { AxiosRequestConfig } from "axios";
import qs from "qs";
import { getConfig } from "@di";
import { getTurnaroundDetailDTOMock } from "@services/mocks/turnMocks";
import { getDetectionFullDTOMock } from "@services/mocks/detectionMocks";
export type TApiUrlWithoutPrefix = keyof paths;

type TUrlWithMethod<Method extends "get" | "post"> = {
  [K in keyof paths]: paths[K] extends {
    [key in Method]: { responses: { 200: any } };
  }
    ? K
    : never;
}[keyof paths];

export type TUrlWithGet = TUrlWithMethod<"get">;

export type TApiGetResponse<url extends TUrlWithGet> =
  paths[url]["get"]["responses"][200]["content"]["application/json"];

export type TGetUrlWithQueryParams = {
  [K in keyof paths]: paths[K] extends { get: { parameters?: { query?: any } } }
    ? K
    : never;
}[keyof paths];

export type TApiGetQueryParams<url extends TGetUrlWithQueryParams> =
  NonNullable<paths[url]["get"]["parameters"]>["query"];

export const API_V2_PREFIX = "/api/v2";

export type TUrlWithPost = TUrlWithMethod<"post">;

export type TApiPostResponse<url extends TUrlWithPost> =
  paths[url]["post"]["responses"][200]["content"]["application/json"];

export type TApiPostParams<url extends TUrlWithPost> = NonNullable<
  paths[url]["post"]["requestBody"]
>["content"]["application/json"];

export type TApiConfigReturnType<T, P = any> = {
  config: AxiosRequestConfig<P> & { url: string };
  getMockResponse: ReturnType<typeof generateMockData<T>>;
};

// /api/v2/stands/
const allStandsUrl: TApiUrlWithoutPrefix = "/stands/";

export type TGetStandsResponse = TApiGetResponse<typeof allStandsUrl>;

export const getStandsApiConfig =
  (): TApiConfigReturnType<TGetStandsResponse> => {
    return {
      config: {
        url: API_V2_PREFIX + allStandsUrl,
        method: "GET",
      },
      getMockResponse: generateMockData<TGetStandsResponse>([]),
    };
  };

// /api/v2/stands/{stand_id}/turnarounds/
const standTurnaroundsUrl: TApiUrlWithoutPrefix =
  "/stands/{stand_id}/turnarounds/";

export type TStandTurnaroundsResponse = TApiGetResponse<
  typeof standTurnaroundsUrl
>;

export type TStandTurnaroundsParams = TApiGetQueryParams<
  typeof standTurnaroundsUrl
>;

export const getStandTurnaroundsApiConfig = (
  standId: string,
  params?: TStandTurnaroundsParams,
  signal?: AbortSignal
): TApiConfigReturnType<TStandTurnaroundsResponse> => {
  if (params) {
    if (!params.limit) {
      params.limit = getConfig().turnaroundsRequestLimit;
    }
    if (params.before) {
      params.before = Math.floor(params.before / 1000);
    }
    if (params.after) {
      params.after = Math.floor(params.after / 1000);
    }
  }
  const getMockResponse = generateMockData<TStandTurnaroundsResponse>([]);

  return {
    config: {
      url: API_V2_PREFIX + standTurnaroundsUrl.replace("{stand_id}", standId),
      method: "GET",
      params,
      signal,
    },
    getMockResponse,
  };
};

// /api/v2/stands/search
const searchTurnaroundsUrl: TApiUrlWithoutPrefix = "/stands/search";

export type TSearchTurnaroundsResponse = TApiGetResponse<
  typeof searchTurnaroundsUrl
>;

export type TSearchTurnaroundsParams = TApiGetQueryParams<
  typeof searchTurnaroundsUrl
>;

export const searchTurnaroundsApiConfig = (
  params: TSearchTurnaroundsParams,
  signal?: AbortSignal
): TApiConfigReturnType<TSearchTurnaroundsResponse> => {
  if (!params.limit) {
    params.limit = getConfig().turnaroundsRequestLimit;
  }

  if (params.before) {
    params.before = Math.round(params.before / 1000);
  }

  return {
    config: {
      url: API_V2_PREFIX + "/stands/search",
      method: "GET",
      params,
      signal,
    },
    getMockResponse: generateMockData<TSearchTurnaroundsResponse>([]),
  };
};

// /api/v2/stands/{stand_id}/turnarounds/{turnaround_id}

const turnaroundUrl: TApiUrlWithoutPrefix =
  "/stands/{stand_id}/turnarounds/{turnaround_id}";

export type TTurnaroundResponse = TApiGetResponse<typeof turnaroundUrl>;

export const getFullTurnaroundApiConfig = (
  standId: string,
  turnId: string,
  isNextFlightsEnabled: boolean,
  signal?: AbortSignal
): TApiConfigReturnType<TTurnaroundResponse> => {
  return {
    config: {
      url:
        API_V2_PREFIX +
        turnaroundUrl
          .replace("{stand_id}", standId)
          .replace("{turnaround_id}", turnId),
      method: "GET",
      params: {
        with_next_flight: isNextFlightsEnabled,
      },
      signal,
    },
    getMockResponse: generateMockData<TTurnaroundResponse>({
      online: {},
      current_ts: 162425600,
      last_image_ts: { common: 162425600 },
      last_prediction_ts: { common: 162425600 },
      turnaround: {
        ...getTurnaroundDetailDTOMock(),
        detections: [],
      },
    }),
  };
};

// /api/v2/stands/{stand_id}/turnarounds/{turnaround_id}/timeline

const turnaroundTimelineUrl: TApiUrlWithoutPrefix =
  "/stands/{stand_id}/turnarounds/{turnaround_id}/timeline";

export type TTurnaroundTimelineResponse = TApiGetResponse<
  typeof turnaroundTimelineUrl
>;

export const getTurnaroundTimelineApiConfig = (
  standId: string,
  turnId: string,
  isNextFlightsEnabled: boolean,
  signal?: AbortSignal
): TApiConfigReturnType<TTurnaroundTimelineResponse> => {
  return {
    config: {
      url: `/api/v2/stands/${standId}/turnarounds/${turnId}/timeline`,
      params: {
        with_next_flight: isNextFlightsEnabled,
      },
      signal,
    },
    getMockResponse: generateMockData<TTurnaroundTimelineResponse>({
      current_ts: 162425600,
      last_image_ts: { common: 162425600 },
      last_prediction_ts: { common: 162425600 },
      online: { common: true },
      turnaround: getTurnaroundDetailDTOMock(),
      detections: [],
      camera_outages: [],
    }),
  };
};

// /api/v2/stands/detections/{detection_id}
const detectionUrl: TApiUrlWithoutPrefix = "/stands/detections/{detection_id}";

export type TDetectionResponse = TApiGetResponse<typeof detectionUrl>;

export const getFetchDetectionApiConfig = (
  id: string
): TApiConfigReturnType<TDetectionResponse> => {
  return {
    config: {
      url: API_V2_PREFIX + `/stands/detections/${id}`,
    },
    getMockResponse: generateMockData<TDetectionResponse>(
      getDetectionFullDTOMock()
    ),
  };
};

// /api/v2/stands/turnarounds/
const activeTurnaroundsUrl: TGetUrlWithQueryParams = "/stands/turnarounds";

export type TActiveTurnaroundsResponse = TApiGetResponse<
  typeof activeTurnaroundsUrl
>;

export type TActiveTurnaroundsParams = TApiGetQueryParams<
  typeof activeTurnaroundsUrl
>;

export const getActiveTurnaroundsApiConfig = (
  lastRequestTs?: number
): TApiConfigReturnType<TActiveTurnaroundsResponse> => {
  const params: TActiveTurnaroundsParams = {};
  if (lastRequestTs) {
    params.since = lastRequestTs / 1000;
  }

  const getMockResponse = generateMockData<TActiveTurnaroundsResponse>([]);

  return {
    config: {
      url: API_V2_PREFIX + "/stands/turnarounds",
      method: "GET",
      params,
    },
    getMockResponse,
  };
};

// /api/v2/stands/{stand_id}/timeline

const standTimelineUrl: TGetUrlWithQueryParams = "/stands/{stand_id}/timeline";

export type TStandTimelineResponse = TApiGetResponse<typeof standTimelineUrl>;

export type TStandTimelineParams = TApiGetQueryParams<typeof standTimelineUrl>;

export const getTimelineApiConfig = (
  standId: string,
  startTs: number,
  endTs?: number | null
): TApiConfigReturnType<TStandTimelineResponse> => {
  startTs = startTs / 1000;

  const params: TStandTimelineParams = {
    start_ts: startTs,
  };

  if (endTs) {
    params.end_ts = endTs / 1000;
  }

  return {
    config: {
      url: `/api/v2/stands/${standId}/timeline`,
      params,
    },
    getMockResponse: generateMockData<TStandTimelineResponse>({
      current_ts: 162425600,
      last_image_ts: { common: 162425600 },
      last_prediction_ts: { common: 162425600 },
      turnarounds: [] as TStandTimelineResponse["turnarounds"],
      detections: [],
      camera_outages: [],
      online: { common: true },
    }),
  };
};

// /api/v2/stands/incidents/summary/
const incidentsSummaryUrl: TApiUrlWithoutPrefix = "/stands/incidents/summary/";

export type TIncidentsSummaryResponse = TApiGetResponse<
  typeof incidentsSummaryUrl
>;

export const getIncidentsSummaryApiConfig =
  (): TApiConfigReturnType<TIncidentsSummaryResponse> => {
    return {
      config: {
        url: API_V2_PREFIX + incidentsSummaryUrl,
      },
      getMockResponse: generateMockData<TIncidentsSummaryResponse>([]),
    };
  };

// /api/v2/stands/incidents
const incidentsUrl: TApiUrlWithoutPrefix = "/stands/incidents";

export type IncidentsResponse = TApiGetResponse<typeof incidentsUrl>;

export type IncidentsParams = NonNullable<
  TApiGetQueryParams<typeof incidentsUrl>
>;

export const getIncidentsApiConfig = (
  params: IncidentsParams = {}
): TApiConfigReturnType<IncidentsResponse> => {
  return {
    config: {
      url: API_V2_PREFIX + incidentsUrl,
      paramsSerializer: (v) => {
        return qs.stringify(v, { arrayFormat: "repeat" });
      },
      params,
    },
    getMockResponse: generateMockData<IncidentsResponse>({
      incidents: [],
      total_count: 0,
    }),
  };
};

// /incident_configs/safety_events/
const safetyConfigsUrl: TApiUrlWithoutPrefix =
  "/incident_configs/safety_events/";

export type TSafetyConfigsResponse = TApiGetResponse<typeof safetyConfigsUrl>;

export const getSafetyConfigsApiConfig =
  (): TApiConfigReturnType<TSafetyConfigsResponse> => {
    return {
      config: {
        url: API_V2_PREFIX + safetyConfigsUrl,
      },
      getMockResponse: generateMockData<TSafetyConfigsResponse>([]),
    };
  };

// /api/v2/stands/{stand_id}/export
const exportUrl: TApiUrlWithoutPrefix = "/stands/{stand_id}/export";

export type TExportResponse = TApiGetResponse<typeof exportUrl>;

type TExportParams = TApiGetQueryParams<typeof exportUrl>;

export const getExportApiConfig = (
  standId: string,
  params: TExportParams
): TApiConfigReturnType<TExportResponse> => {
  params = {
    ...params,
  };
  if (params.start_ts) {
    params.start_ts = Math.floor(params.start_ts / 1000);
  }
  if (params.end_ts) {
    params.end_ts = Math.floor(params.end_ts / 1000);
  }

  return {
    config: {
      url: API_V2_PREFIX + exportUrl.replace("{stand_id}", standId),
      params,
    },
    getMockResponse: generateMockData<TExportResponse>({
      detections: [],
      turnarounds: [],
    }),
  };
};

// /user-watchlist/current

const userWatchlistUrl: TApiUrlWithoutPrefix = "/user-watchlist/current";

export type TUserWatchlistGetResponse =
  | TApiGetResponse<typeof userWatchlistUrl>
  | undefined;

export const getUserWatchlistApiConfig =
  (): TApiConfigReturnType<TUserWatchlistGetResponse> => {
    return {
      config: {
        url: API_V2_PREFIX + userWatchlistUrl,
      },
      getMockResponse: generateMockData<TUserWatchlistGetResponse>(
        {} as TUserWatchlistGetResponse
      ),
    };
  };

export type TUserWatchlistPostResponse = TApiPostResponse<
  typeof userWatchlistUrl
>;

export type TUserWatchlistPostParams = TApiPostParams<typeof userWatchlistUrl>;

export const getUserWatchlistPostApiConfig = (
  data: TUserWatchlistPostParams
): TApiConfigReturnType<TUserWatchlistPostParams> => {
  return {
    config: {
      url: API_V2_PREFIX + userWatchlistUrl,
      method: "POST",
      data,
    },
    getMockResponse: generateMockData<TUserWatchlistPostParams>(
      {} as TUserWatchlistPostParams
    ),
  };
};

// /turnarounds/${turnaround_id}/operation-widgets

const operationWidgetsUrl: TApiUrlWithoutPrefix =
  "/turnarounds/{turnaround_id}/operation-widgets";

export type TOperationWidgetsResponse = TApiGetResponse<
  typeof operationWidgetsUrl
>;

export const getOperationWidgetsApiConfig = (
  id: string,
  signal?: AbortSignal
): TApiConfigReturnType<TOperationWidgetsResponse> => {
  return {
    config: {
      url: API_V2_PREFIX + operationWidgetsUrl.replace("{turnaround_id}", id),
      signal,
    },
    getMockResponse: generateMockData<TOperationWidgetsResponse>([]),
  };
};
