import React, { useEffect } from "react";
import { backupUrlPath } from "./helpers";
import { useAuth } from "react-oidc-context";

type Props = {
  onError: (er: Error) => void;
};

const SignRedirect: React.FC<React.PropsWithChildren<Props>> = ({
  onError,
}) => {
  const { signinRedirect } = useAuth();

  useEffect(() => {
    backupUrlPath();
    signinRedirect({
      extraQueryParams: {
        // It used by ID service
        next: window.location.href,
      },
    }).catch(onError);
  }, []);

  return null;
};

export default SignRedirect;
