import sortBy from "lodash/sortBy";
import maxBy from "lodash/maxBy";
import minBy from "lodash/minBy";
import partition from "lodash/partition";
import uniq from "lodash/uniq";
import { contains, intersection, intersects } from "@shared/rayjs";

import { AIRCRAFT_ON_STAND } from "@constants";
import { getConfig } from "@di";
import { DetectionPartial } from "@models/detection";
import { Turnaround } from "@models/turnaround";
import {
  ApiPostMiddlewareFunction,
  TimelineResult,
  TurnaroundTimelineResult,
} from "../types";
import { AssaiaUser } from "@shared/components/OIDCApp";

export const leaveFirstAndLastWalkaround: ApiPostMiddlewareFunction<
  TimelineResult | TurnaroundTimelineResult | null
> = (resp) => {
  if (!resp) {
    return resp;
  }

  const aircraft = resp.detections.find((d) => d.type === "aircraft");
  const aircraftEnded = aircraft?.endType;
  let types = resp.detections
    .filter(function (d) {
      return d.type.startsWith("walkaround_");
    })
    .map(function (d) {
      return d.type;
    });
  if (!types.length) {
    return resp;
  }

  types = uniq(types);
  types.forEach(function (type) {
    const [walks, others] = partition(resp.detections, function (d) {
      return d.type === type;
    });
    if (walks.length === 1) {
      return;
    }

    const result = [];
    const start = minBy(walks, "start") as DetectionPartial;
    start.endLabel = undefined;
    start.end = start.start + 10000;
    result.push(start);

    if (aircraftEnded) {
      const end = maxBy(walks, "start") as DetectionPartial;
      end.endLabel = undefined;
      end.end = end.start + 10000;
      result.push(end);
    }

    resp.detections = [...others, ...result];
  });
  return resp;
};

/**
 * Bounds detection's start/end according to its lifecycle
 * pre_aircraft - bounds to [turn.start, aircraft_on_stand.start]
 * post_aircraft - bounds to [aircraft_on_stand.end, turn.end]
 * aircraft - bounds to [aircraft_on_stand.start, aircraft_on_stand.end]
 */
export const lifecycleMiddleware = <
  T extends TurnaroundTimelineResult | TimelineResult | null,
>(
  resp: T,
  // eslint-disable-next-line unused-imports/no-unused-vars, @typescript-eslint/no-unused-vars
  user: AssaiaUser
) => {
  if (!resp) {
    return resp;
  }

  const { detectionTypesMap } = getConfig();

  function fixDetections(
    turn: Turnaround,
    detections: DetectionPartial[]
  ): DetectionPartial[] {
    const allAircrafts = detections
      .filter((d) => d.type === AIRCRAFT_ON_STAND)
      .map((v) => [v.start, v.end] as const);
    const aircraft = allAircrafts.length
      ? {
          start: Math.min(...allAircrafts.map((v) => v[0])),
          end: Math.max(...allAircrafts.map((v) => v[1] || 0)) || null,
        }
      : null;

    const aircraftBounds: [number, number | null] | null = aircraft
      ? [aircraft.start, aircraft.end]
      : null;

    const preAircraftBounds: [number, number] = [
      turn.start,
      aircraft?.start || Date.now(),
    ];

    const postAircraftBounds: [number, number] | null = aircraft?.end
      ? [aircraft.end, turn.end || Date.now()]
      : null;

    return detections.reduce<DetectionPartial[]>((accum, detection) => {
      const detectionType = detectionTypesMap[detection.type];
      if (!detectionType) {
        return accum;
      }
      const { lifecycles } = detectionType;
      if (!lifecycles) {
        accum.push(detection);
        return accum;
      }

      if (lifecycles.includes("continuous")) {
        accum.push(detection);
      }

      const boundDetections = (
        bounds: [number, number | null] | null,
        target: "aircraft" | "pre_aircraft" | "post_aircraft"
      ) => {
        const valid =
          lifecycles.includes(target) &&
          bounds &&
          bounds[0] < (bounds[1] || Number.MAX_SAFE_INTEGER);
        if (!valid) {
          return;
        }

        if (contains(bounds, [detection.start, detection.end])) {
          accum.push(detection);
          return;
        }

        const res = intersection([detection.start, detection.end], bounds);
        res &&
          res[0] &&
          accum.push({
            ...detection,
            id: `${detection.id}_${target}`,
            originalId: detection.id,
            start: res[0],
            end: res[1],
            origin: "middleware",
          });
      };

      boundDetections(aircraftBounds, "aircraft");
      boundDetections(preAircraftBounds, "pre_aircraft");
      boundDetections(postAircraftBounds, "post_aircraft");

      return accum;
    }, []);
  }

  const turnarounds =
    "turnaround" in resp
      ? sortBy([resp.turnaround], "start")
      : resp.turnarounds;

  if (!turnarounds.length) {
    resp.detections = resp.detections.filter((d) => {
      const detectionType = detectionTypesMap[d.type];
      return detectionType?.lifecycles.includes("continuous");
    });
    return resp;
  }

  const newDetections: DetectionPartial[] = [];

  //Dont filter detections if request was for particular turn
  //TODO need a better way to detect with case
  if (!("turnaround" in resp)) {
    turnarounds.forEach((turn) => {
      const [turnDetections, otherDetections] = partition(
        resp.detections,
        (d) =>
          intersects([turn.start, turn.end || null], [d.start, d.end || null])
      );
      newDetections.push(...fixDetections(turn, turnDetections));
      resp.detections = otherDetections;
    });

    resp.detections = newDetections;
  }

  return resp;
};
