import React from "react";
import { Spinner } from "@frontend/airfoil";
import s from "./style.module.scss";

const PreLoader: React.FC = () => (
  <div className={s.preLoader}>
    <Spinner />
  </div>
);

export default PreLoader;
