export type NoInfer<T> = [T][T extends any ? 0 : never];

/**
 * Adapted from type-fest's PartialDeep
 */
export type PartialDeep<T> = T extends (...args: any[]) => any
  ? PartialDeepObject<T> | undefined
  : T extends object
    ? T extends ReadonlyArray<infer ItemType> // Test for arrays/tuples, per https://github.com/microsoft/TypeScript/issues/35156
      ? ItemType[] extends T // Test for arrays (non-tuples) specifically
        ? readonly ItemType[] extends T // Differentiate readonly and mutable arrays
          ? ReadonlyArray<PartialDeep<ItemType | undefined>>
          : Array<PartialDeep<ItemType | undefined>>
        : PartialDeepObject<T> // Tuples behave properly
      : PartialDeepObject<T>
    : T;

export type PartialDeepObject<ObjectType extends object> = {
  [KeyType in keyof ObjectType]?: PartialDeep<ObjectType[KeyType]>;
};

/**
 * Lets you pass a deep partial to a slot expecting a type.
 *
 * @returns whatever you pass in
 */
export const fromPartial = <T>(mock: PartialDeep<NoInfer<T>>): T => {
  return mock as T;
};

/**
 * Lets you pass anything to a mock function, while also retaining
 * autocomplete for when you _do_ want to pass the exact thing.
 *
 * @returns whatever you pass in
 */
export const fromAny = <T, U>(mock: U | NoInfer<T>): T => {
  return mock as T;
};

/**
 * Forces you to pass the exact type of the thing the slot requires
 *
 * @returns whatever you pass in
 */
export const fromExact = <T>(mock: T): T => {
  return mock;
};
