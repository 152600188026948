import { TDashboardConfig } from "@frontend/configuration";
import { OpenIdConfig } from "@models/common";
import { AssaiaUser } from "@shared/components/OIDCApp";
import { IS_DEV } from "@constants";

const DEVELOPMENT_HEADER = "verify-tool-development";

export class InitialApi {
  getInitialOpenIdCreds = async (): Promise<OpenIdConfig> => {
    const headers = new Headers();

    if (IS_DEV) {
      headers.append(DEVELOPMENT_HEADER, "true");
    }

    const response = await fetch("/init.json", { headers });

    if (!response.ok) {
      throw response;
    }

    return await response.json();
  };

  getConfig = async (token: string): Promise<TDashboardConfig> => {
    const response = await fetch(
      `configuration/config.json?${new URLSearchParams({ token }).toString()}`
    );

    if (!response.ok) {
      throw response;
    }

    return await response.json();
  };

  getImgToken = async (user: AssaiaUser): Promise<string> => {
    const response = await fetch("/api/account/token", {
      headers: {
        Authorization: "JWT " + user.id_token,
      },
    });

    const { token } = await response.json();

    return token;
  };
}
