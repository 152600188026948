export const NBSP = "\u00A0";

export const IS_DEV = process.env.NODE_ENV === "development";

export const LOCAL_BACKEND_URL = process.env.LOCAL_BACKEND_URL;
export const ENABLE_AUTH = !LOCAL_BACKEND_URL;

export const TURNAROUND_MIN_DURATION = 60000;

export const EMPTY_VALUE = "--";
