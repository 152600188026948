import { generateMockData } from "@services/mocks/mockUtils";
import { isFiniteNumber } from "@services/utils";
import {
  TUrlWithPost,
  TApiConfigReturnType,
  API_V2_PREFIX,
  TApiPostResponse,
  TApiPostParams,
} from ".";

// /flights/arrivals /flights/departures
const arrivalFlightsUrl: TUrlWithPost = "/flights/arrivals";

const departureFlightsUrl: TUrlWithPost = "/flights/departures";

export type TFlightsResponse = TApiPostResponse<typeof arrivalFlightsUrl> &
  TApiPostResponse<typeof departureFlightsUrl>;

export type TFlightsParams = TApiPostParams<typeof arrivalFlightsUrl> &
  TApiPostParams<typeof departureFlightsUrl>;

export const getFlightsApiConfig = (
  type: "arrivals" | "departures",
  params: TFlightsParams,
  signal?: AbortSignal
): TApiConfigReturnType<TFlightsResponse, TFlightsParams> => {
  const payload = {
    ...params,
    from: isFiniteNumber(params.from) ? params.from / 1000 : undefined,
    to: isFiniteNumber(params.to) ? params.to / 1000 : undefined,
  };

  return {
    config: {
      url:
        API_V2_PREFIX +
        (type === "arrivals" ? arrivalFlightsUrl : departureFlightsUrl),
      method: "POST",
      data: payload,
      signal,
    },
    getMockResponse: generateMockData<TFlightsResponse>([]),
  };
};
