import React, { createContext, useEffect, useMemo } from "react";
import { User } from "oidc-client-ts";

import PreLoader from "./PreLoader";
import SignRedirect from "./signRedirect";
import { AuthProvider, useAuth } from "react-oidc-context";
import {
  TUserManagerProps,
  createUserManager,
  getUrlPathBackup,
  removeUrlPathBackup,
} from "./helpers";
import DashboardOff from "./DashboardOff";

type Props = TUserManagerProps & {
  onError: (er: Error) => void;
  /**
   * Used to replace /auth/callback by correct URL before auth
   */
  onSigninCallback: (urlToRestore: string) => void;
};

export type AssaiaUser = User & {
  profile: User["profile"] & {
    is_admin: boolean;
    is_notification_admin: boolean;
    is_pts_admin: boolean;
    is_user_admin: boolean;
    roles?: string[];
    resources?: string[];
    profile: {
      full_name?: string;
      company?: string;
    };
  };
};

export type OIDCContextValue = {
  user: AssaiaUser;
  logout: () => void;
};

export const OIDCContext = createContext<OIDCContextValue>(
  {} as OIDCContextValue,
);

const AuthContainer: React.FC<React.PropsWithChildren<Props>> = ({
  children,
  onError: errorCb,
}) => {
  const auth = useAuth();
  const contextValue = useMemo<OIDCContextValue | null>(() => {
    if (!auth.user) {
      return null;
    }

    return {
      user: auth.user as AssaiaUser,
      logout: () => auth.signoutRedirect(),
    };
  }, [auth.user]);

  const onError = (er: Error) => {
    let msg = er.message || "";

    if (msg.includes("in the future") || msg.includes("in the past"))
      {
        msg =
          "Your local time is incorrect.\n Please synchronize your local time with time server\n and try again.";
      }
    else {
      msg = "Authentication error.";
    }
    er.message = msg;
    errorCb(er);
  };

  useEffect(() => auth.error && onError(auth.error), [auth.error]);

  if (
    auth.isLoading ||
    auth.activeNavigator === "signinSilent" ||
    auth.activeNavigator === "signoutRedirect"
  ) {
    return <PreLoader />;
  }

  if (auth.error) {
    return <div>{auth.error.message}</div>;
  }

  if (auth.isAuthenticated && contextValue) {
    return (
      <OIDCContext.Provider value={contextValue}>
        {children}
      </OIDCContext.Provider>
    );
  }

  return <SignRedirect onError={onError} />;
};

const OIDCApp: React.FC<React.PropsWithChildren<Props>> = ({
  children,
  ...props
}) => {
  const {
    clientId,
    authority,
    overrideConfig = {},
    onAddSilentRenewError,
    customLogger,
    logLevel,
    onSigninCallback,
  } = props;
  const userManager = useMemo(
    () =>
      createUserManager({
        clientId,
        authority,
        overrideConfig,
        onAddSilentRenewError,
        customLogger,
        logLevel,
      }),
    [],
  );

  if (window.location.pathname === "/off") {
    return <DashboardOff />;
  }

  return (
    <AuthProvider
      userManager={userManager}
      onSigninCallback={() => {
        const restoredValue = getUrlPathBackup() || "";
        customLogger?.debug("[OidcClient] Restore URL", restoredValue);

        onSigninCallback(restoredValue);

        removeUrlPathBackup();
      }}
    >
      <AuthContainer {...props}>{children}</AuthContainer>
    </AuthProvider>
  );
};

export default OIDCApp;
